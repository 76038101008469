import Axios from "axios";

Axios.interceptors.request.use(
  (config) => {
    const apiVersion = localStorage.getItem("api-version");

    if (apiVersion) {
      config.headers['x-version'] = apiVersion;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
