import React, { Component } from "react";

class DeliveryTime extends Component {
  render() {
    const { rate_and_time } = this.props;

    if (!Array.isArray(rate_and_time) || rate_and_time.length === 0) {
      return <span className="delivery-time">Indisponível</span>;
    }

    const times = rate_and_time
      .map((rate) => (rate && rate.time ? parseInt(rate.time, 10) : NaN))
      .filter((time) => !isNaN(time) && time > 0);

    if (times.length === 0) {
      return <span className="delivery-time">Indisponível</span>;
    }

    const minTime = Math.min.apply(null, times);
    const maxTime = Math.max.apply(null, times);

    return (
      <span className="delivery-time">
        {minTime} - {maxTime} Min
      </span>
    );
  }
}

export default DeliveryTime;
