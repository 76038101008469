import React, { Component } from "react";
import { withRouter } from "react-router";
import GoogleAnalytics from "react-ga";
import { addScriptDefault } from "meta-pixel";
import Axios from "axios";
import { GET_MERCHANT_INFO_BY_ID_URL } from "./configs";

const withTracker = (WrappedComponent, options = {}) => {
  const HOC = class extends Component {
    state = {
      merchantData: null,
    };

    async fetchMerchantInfo() {
      const merchantId = localStorage.getItem("lastMerchantOpen");
      if (!merchantId) {
        console.error("Nenhum merchant ID encontrado no localStorage.");
        return null;
      }

      try {
        const response = await Axios.post(
          `${GET_MERCHANT_INFO_BY_ID_URL}/${merchantId}`
        );
        this.setState({ merchantData: response.data });
        console.log("Dados do merchant carregados:", response.data);
        return response.data;
      } catch (error) {
        console.error("Erro ao buscar informações do merchant:", error);
        return null;
      }
    }

    initializeTracking(merchantData, page) {
      if (!merchantData) {
        console.error("Nenhum merchant ativo encontrado.");
        return;
      }

      const {
        meta_pixel_id,
        meta_pixel_active,
        google_analytics_id,
        google_analytics_active,
        google_tag_manager_id,
        google_tag_manager_active,
      } = merchantData;

      if (google_analytics_active && google_analytics_id) {
        GoogleAnalytics.initialize(google_analytics_id);
        GoogleAnalytics.set({ page, ...options });
        GoogleAnalytics.pageview(page);
      }

      if (meta_pixel_active && meta_pixel_id) {
        if (!window.fbqInitialized) {
          const fbq = addScriptDefault();
          fbq("set", "autoConfig", meta_pixel_id);
          fbq("init", meta_pixel_id);
          fbq("track", "PageView");
          window.fbqInitialized = true;
        }
      }

      if (google_tag_manager_active && google_tag_manager_id) {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", google_tag_manager_id);
      }
    }

    triggerCustomPixelEvent = (eventName, eventParams = {}) => {
      if (window.fbqInitialized) {
        window.fbq("track", eventName, eventParams);
      } else {
        console.error(
          "Meta Pixel não está inicializado, o evento não pode ser disparado."
        );
      }
    };

    async componentDidMount() {
      const page = this.props.location.pathname;
      const merchantData = await this.fetchMerchantInfo();
      if (merchantData) {
        this.initializeTracking(merchantData, page);
      }
    }

    componentDidUpdate(prevProps) {
      const prevPage = prevProps.location.pathname;
      const currentPage = this.props.location.pathname;

      if (prevPage !== currentPage) {
        this.initializeTracking(this.state.merchantData, currentPage);
      }
    }

    reloadTracking = async () => {
      this.setState({ merchantData: null }, async () => {
        const merchantData = await this.fetchMerchantInfo();
        const page = window.location.pathname;
        this.initializeTracking(merchantData, page);
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          triggerPixelEvent={this.triggerCustomPixelEvent}
          reloadTracking={this.reloadTracking}
        />
      );
    }
  };

  return withRouter(HOC);
};

export default withTracker;
