import { useEffect } from "react";

const useTrackFirstURL = () => {
  useEffect(() => {
    const firstSlug = localStorage.getItem("firstSlug");

    if (!firstSlug) {
      const currentPath = window.location.pathname;
      localStorage.setItem("firstSlug", currentPath);
    }
  }, []);
};

export default useTrackFirstURL;
