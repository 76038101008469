import { PIX, PLACE_ORDER } from "./actionTypes";

const initialState = {
    checkout: [],
    pix: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PLACE_ORDER:
            return { ...state, checkout: action.payload };
        case PIX:
            return { ...state, pix: action.payload };
        default:
            return state;
    }
}
